import React, { useState, useEffect } from "react";
import "./Navbar.css";
import logo from "../../images/Logo.svg";
import menu from "./icons/menu.svg";
import menuGif from "./icons/menu.gif";
import presentation from "./icons/presentation.svg";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Toaster, toast } from "react-hot-toast";
import { HiOutlineLogout } from "react-icons/hi";
import { connect } from "react-redux";
import { updateBuildDemoStatus } from "../../redux/action/others";
import { getUserDetailsFromLocalStorage } from "../LocalStorage";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { HiOutlineHome } from "react-icons/hi";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
const Navbar = ({
  selectedGender,
  onLoginSuccess,
  activeItem,
  setActiveItem,
  updateBuildDemoStatus,
  demoSelected,
  setDemoSelected,
  products,
}) => {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState("");

  const [menuIsHovered, setMenuIsHovered] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    const userDetails = getUserDetailsFromLocalStorage();
    const activeButton = localStorage.getItem("activeItem");

    setActiveItem(activeButton);
    console.log("activeItem", activeItem);
    setUserDetails(userDetails.user);
  }, [activeItem, setActiveItem]);

  useEffect(() => {
    if (demoSelected) {
      localStorage.setItem("activeItem", "Demo");
      setActiveItem("Demo");
    }
  }, [demoSelected, setActiveItem]);

  const handleClose = (selectedButton) => {
    if (selectedButton === "Trends") {
      localStorage.setItem("activeItem", "viewTrends");
      navigate("/viewtrends");
      setActiveItem("viewTrends");
    } else if (selectedButton === "Home") {
      const activeButton = localStorage.setItem("activeItem", "Home");
      setActiveItem(activeButton);
      navigate("/dashboard");
    } else if (selectedButton === "Profile") {
      localStorage.setItem("activeItem", "Profile");

      if (userDetails?.role === "Admin") {
        navigate("/profile");
      } else if (userDetails?.role === "SuperAdmin") {
        navigate("/super-admin-profile");
      }

      setActiveItem("Profile");
    }
    setAnchorEl(null);
  };

  const handleLogout = () => {
    try {
      localStorage.removeItem("userDetails");
      toast.success(
        "You have been logged out. Redirecting to the Login page..."
      );

      setTimeout(() => {
        navigate("/");
        onLoginSuccess(false);
      }, 1000);
    } catch (error) {
      console.error("Logout failed:", error);
      toast.error("An error occurred during logout. Please try again.");
    }
  };

  const handleViewTrends = () => {
    console.log("clicked");
    localStorage.setItem("activeItem", "viewTrends");
    navigate("/viewtrends");
    setActiveItem("viewTrends");
  };
  const handleDemo = () => {
    setDemoSelected(true);
    updateBuildDemoStatus(true);
  };

  const handleDashboard = () => {
    localStorage.setItem("activeItem", "Dashboard");
    navigate("/landingpage");
    setActiveItem("Dashboard");
  };
  const handleProfile = () => {
    localStorage.setItem("activeItem", "Profile");

    if (userDetails?.role === "Admin") {
      navigate("/profile");
    } else if (userDetails?.role === "SuperAdmin") {
      navigate("/super-admin-profile");
    }

    setActiveItem("Profile");
  };

  const isDashboardPage = window.location.pathname === "/dashboard";
  console.log("isDashboardPage", products > 0);
  return (
    <div className="navbar-content">
      <Toaster />
      <div className="navbar-left-icons">
        <div className="menu">
          <button
            style={{ border: "none", backgroundColor: "transparent" }}
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <div className="menu inactive">
              <div
                onMouseEnter={() => setMenuIsHovered(true)}
                onMouseLeave={() => setMenuIsHovered(false)}
              >
                {!menuIsHovered && (
                  <img
                    src={menu}
                    className="icon"
                    id="editIcon"
                    alt=""
                    width={24}
                    height={24}
                  />
                )}
                {menuIsHovered && (
                  <img
                    src={menuGif}
                    className="icon"
                    id="editIcon"
                    alt=""
                    width={24}
                    height={24}
                  />
                )}
              </div>
              <div>
                <span className="menu-text">Menu</span>
              </div>
            </div>
          </button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              "& .MuiMenu-paper": {
                width: 200,
                height: 190,
                padding: "15px",
              },
              "& .MuiMenuItem-root": {
                fontSize: "16px",
                color: "#1A1B21",
                fontWeight: "400",
                borderBottom: "1px solid #B5C4FF",
                minHeight: "unset", // This prevents the default min-height from being applied
                "&:hover": {
                  background:
                    "linear-gradient(to right, #26408a 0%, #196d92 20%, #49883e 40%, #26408A 60%)",
                  "-webkit-background-clip": "text",
                  "-webkit-text-fill-color": "transparent",
                  fontSize: "16px",
                  fontWeight: "700",
                },
              },
            }}
          >
            <MenuItem
              onClick={!demoSelected ? () => handleClose("Home") : undefined}
              className={activeItem === "Home" ? "activeMenuItem" : "menu-item"}
            >
              <div>
                <HiOutlineHome size={24} />
              </div>
              Home
            </MenuItem>
            <MenuItem
              onClick={!demoSelected ? () => handleClose("Trends") : undefined}
              className={
                activeItem === "viewTrends" ? "activeMenuItem" : "menu-item"
              }
            >
              {" "}
              <div>
                <TrendingUpIcon size={24} />
              </div>
              trends
            </MenuItem>
            <MenuItem
              onClick={
                userDetails?.role === "Admin" ||
                userDetails?.role === "SuperAdmin"
                  ? () => handleClose("Profile")
                  : undefined
              }
              className={
                activeItem === "Profile" ? "activeMenuItem" : "menu-item"
              }
            >
              <div>
                <PermIdentityIcon />
              </div>{" "}
              {userDetails?.username}
            </MenuItem>

            <MenuItem onClick={handleLogout} className="menu-item">
              <div>
                <HiOutlineLogout size={24} />
              </div>
              Log Out
            </MenuItem>
          </Menu>
        </div>
        <div
          className={activeItem === "Home" ? "active-item" : "inactive"}
          style={{ cursor: !demoSelected ? "pointer" : "not-allowed" }}
          onClick={!demoSelected ? () => handleClose("Home") : undefined}
        >
          <span className="navbar-icon-text">Home</span>
        </div>

        <div
          className={activeItem === "viewTrends" ? "active-item" : "inactive"}
          style={{ cursor: !demoSelected ? "pointer" : "not-allowed" }}
          onClick={!demoSelected ? handleViewTrends : undefined}
        >
          <span className="navbar-icon-text">Trends</span>
        </div>
      </div>
      <div
        onClick={!demoSelected ? handleDashboard : undefined}
        style={{ cursor: !demoSelected ? "pointer" : "not-allowed" }}
      >
        <img src={logo} alt="" width={180} height={40} />
      </div>
      <div className="navbar-right-icons">
        {(userDetails?.role === "Admin" ||
          userDetails?.role === "SuperAdmin" ||
          userDetails?.role === "Marketing") && (
          <button
            className={
              activeItem === "Demo"
                ? "presentation-button-active"
                : "presentation-button"
            }
            onClick={isDashboardPage && products > 0 ? handleDemo : () => {}}
            style={{
              cursor:
                isDashboardPage && products > 0 ? "pointer" : "not-allowed",
            }}
            disabled={!isDashboardPage || products <= 0}
          >
            <div>
              <img src={presentation} alt="Presentation Icon" />
            </div>
            <div>
              <span className="navbar-icon-text">Create Your Presentation</span>
            </div>
          </button>
        )}
        <button
          disabled={demoSelected}
          className={
            activeItem === "Profile"
              ? "presentation-button-active"
              : "presentation-button"
          }
          style={{ cursor: !demoSelected ? "pointer" : "not-allowed" }}
          onClick={
            userDetails?.role === "Admin" || userDetails?.role === "SuperAdmin"
              ? handleProfile
              : undefined
          }
        >
          <div>
            <PermIdentityIcon />
          </div>
          <div>
            <span className="navbar-icon-text">{userDetails?.username}</span>
          </div>
        </button>

        <div className="navbar-signout-button" onClick={handleLogout}>
          <HiOutlineLogout size={32} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  buildDemoStatus: state.getBuildDemoState.selected,
  allProducts: state.allProducts.allProducts,
});

const mapDispatchToProps = {
  updateBuildDemoStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
